import { Button, Result } from 'antd';

import BasicSEO from '@/components/meta/meta';
import { Fragment } from 'react';

export default function Custom404() {
    return (
        <Fragment>
            <BasicSEO title="404 Not Found" description="Not Found Page" />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                window.location.assign('/');
                            }}
                        >
                            Back Home
                        </Button>
                    }
                />
            </div>
        </Fragment>
    );
}
